<p-sidebar [(visible)]="display">
  <p-panelMenu [model]="items" routerLinkActive="active" [multiple]="false" [style]="{'width':'100%'}"></p-panelMenu>


</p-sidebar>
<p-overlayPanel #op>

  {{currentUser.first_name}} {{currentUser.last_name}}

  <br>

  <br>
  <button type="button" pButton pRipple label="logout" icon="pi pi-clone" class="p-button btn-block p-button-primary"
    (click)="logout()"></button>



</p-overlayPanel>

  <div class="sidebar" id="mySidebar" [ngClass]=" flag ? 'close' : 'open'">
    <div class="p-grid">
      <div class="p-col-12">
        <div class="sidebar-top">

          <div class="logo-wrapper cp" routerLink="/admin">
            <h3>NEXO<span>.</span></h3>
          </div>

          <div class="openbtn hidden-mobile" (click)="flag = !flag">
            <i class="pi pi-list"></i>
          </div>


          <div class="openbtn show-mobile" (click)="display = true">
            <i class="pi pi-list"></i>
          </div>
        </div>
      </div>
    </div>
    <div class="sidebar-inner">
      <div class="p-grid">
        <div class="p-col-12">
          <hr class="line">

          <p-panelMenu [model]="items" [multiple]="false" [style]="{'width':'100%'}"></p-panelMenu>
        </div>

      </div>

    </div>



  </div>



<div id="main" [ngClass]=" flag ? 'open' : 'close'">
  <div class="navbar-top" [ngClass]=" flag ? 'open' : 'close'">

    <div class="openbtn show-mobile" (click)="display = true">
      <i class="pi pi-list"></i>
    </div>

    <div class="logo-wrapper cp" routerLink="/admin">
    </div>


    <div class="section-wrapper hidden-mobile">
      <ul>
        <li class="">MantovaTours Admin</li>

      </ul>

    </div>

    <div class="search-wrapper hidden-mobile">

      <!--
       <select #langSelect (change)="translate.use(langSelect.value)">
         <option *ngFor="let lang of translate.getLangs()" [value]="lang" [selected]="lang === translate.currentLang">{{ lang }}</option>
       </select>
   -->

    </div>


    <div class="user-menu">
      <ul>



        <li (click)="op.toggle($event)"><i class="pi pi-user"></i> <span
            class="hidden-mobile">{{currentUser.first_name}} {{currentUser.last_name}}</span>
        </li>
        <li class="hidden-mobile"> <i class="pi pi-clock"></i> <span class="hidden-mobile"
            (click)="logout()">logout</span></li>


      </ul>
    </div>



  </div>
  <router-outlet></router-outlet>

  <ngx-spinner bdColor="rgba(51,51,51,0.2)" size="medium" color="#fff" type="ball-scale-multiple">
  </ngx-spinner>