<app-topnav></app-topnav>



<div class="main-inner without-top-bar">


    <div class="p-grid">

        <div class="p-col-12 p-md-8">
            <h3>Ultime Tour</h3>

            <div class="p-grid">



                <div *ngFor="let tour of tours" class="p-col-12 p-md-6 featured-card">

                    <p-card [routerLink]="['/admin/tours/edit', tour.id]" class="cp">
                        <ngx-progressive-image-loader filter="blur(3px)
                        drop-shadow(0 0 2.75rem green)">



                                <div class="image-home">
                                    <img [src]="'https://api.mantovatours.com/media/slideshow/'+ tour.id + '/' + tour.image"
                                        class="img-fluid cp object-fit_cover" priority>
                                </div>

                            </ngx-progressive-image-loader>
                        <div class="p-grid">
                            <div class="p-col-12 p-md-12">

                               
                            </div>

                            <div class="p-col-12 p-md-12">


                                <div class="content">

                                    <div class="p-grid">
                                        <div class="p-col-12 p-md-12">


                                            <h4>{{tour.page_title}}</h4>
                                            <p [innerHTML]="tour.description_1"></p>

                                        </div>


                                    </div>
                                </div>



                            </div>


                        </div>
                    </p-card>

                </div>



            </div>
        </div>


        <div class="p-col-12 p-md-4">

            <h3>Ultime Richieste</h3>
            <p-card >
            <div class="p-grid requests-list">
                <div class="p-col-12 p-md-12">
                    <li *ngFor="let request of requests" class="requests-item">
                        <div class="p-grid">
                            <div class="p-col-12 p-md-9">
                                <p-tag *ngIf="request.tour"> {{request.tour}}</p-tag>

                                <h4 class="p-mt-1">{{request.name}} {{request.surname}}</h4>

                         
                                <p class="p-mt-2">{{request.date | date: 'dd-MM-yyyy'}} {{request.persons}}</p>

                            </div>
                            <div class="p-col-12 p-md-3">
                                <button pButton pRipple icon="pi pi-eye" class="p-button p-button-primary pull-right p-mr-2"
                                (click)="edit(request)"></button>

                            </div>
                        </div>
                    </li>
                </div>
            </div>
        </p-card>
        </div>

    </div>
   

</div>

<p-sidebar [(visible)]="productDialog" position="right"  [style]="{width:'50%'}">
    <ng-template pTemplate="content">
        <div class="p-col-12 p-md-12">
            <p>Richiesta in data {{request.date_created | date: 'dd-MM-yyyy - H:mm'}}</p>

            <h3>{{request.name}} {{request.surname}}</h3>
            <hr class="full">
            <p [innerHTML]="request.email"></p>
            <p [innerHTML]="request.address"></p>
            <p>{{request.date | date: 'dd-MM-yyyy'}}</p>

            <p [innerHTML]="request.persons"></p>
            <p-tag>{{request.tour}}</p-tag>

   
   </div>
    </ng-template>
</p-sidebar>
