import { Component, HostBinding, HostListener, Input, OnInit } from '@angular/core';



@Component({
  selector: 'animations-card',
  templateUrl: './animations-card.component.html',
  styles: [`h1 { font-family: Lato;}`]
})
export class AnimationsCardComponent implements OnInit {


  @Input() element:  any = [];
  @Input() persons:  any = [];
  @Input() areas:  any = [];

  @Input() category:  any = [];
  @Input() comune:  any = [];

  @Input() location:  any = [];
  @Input() name: string;
  selectedPersons: any[];
  heroimage: any;

  constructor(  

    ) { 
    }

  ngOnInit(): void {

    this.heroimage = 'https://api.mantovatours.com/media/slideshow/' + this.element.id + '/' + this.element.image.image_name;
}



}
