<p-card>
    <p-toast key="myKeyUpload"></p-toast>


    <div class="p-grid">

        <div class="p-col-12 p-md-8">
            <div class="left-section-card">
                <div class="p-fluid">
                    <div class="blog-form">





                        <p-table #dt [columns]="cols" [value]="imageInfos | async" [responsive]="true"
                            [autoLayout]="true" [globalFilterFields]="['name', 'description']"
                            dataKey="id" editMode="row"
                            currentPageReportTemplate="Visualizza {first} di {last} su {totalRecords} Items"
                            [paginator]="true" [rows]="10" [showCurrentPageReport]="true">

                            <ng-template pTemplate="header">
                                <tr>

                                  
                                    <th width="70px"></th>

                                    <th>Nome Immagine</th>
                                    <th></th>
                                    <th></th>

                                </tr>

                            </ng-template>
                            <ng-template pTemplate="body" let-image let-editing="editing" let-columns="columns">
                                <tr [pEditableRow]="image">
                               

                                    <td>
                                        
                                        <p-image [src]="image.image" alt="Image" width="100%" preview="true">

                                        </p-image>

                                    </td>
                                    <td><label>{{image.image_name}}</label></td>

                                    <td pEditableColumn> 
                                       
                                        <p-cellEditor>
                                            <ng-template pTemplate="input">

                                                <div class="p-inputgroup">
                                                    <span class="p-inputgroup-addon" (click)="onRowEditSave(image)">
                                                        <i class="pi pi-save"></i>
                                                    </span>
                                                    <input pInputText type="text" [(ngModel)]="image.description">
                                                    <input pInputText type="text" class="page_order" [inputStyle]="{'width':'60px'}" [style]="{'width': '60px'}" [(ngModel)]="image.image_order">

                                                </div>

                                            </ng-template>
                                            <ng-template pTemplate="output">
                                                <div class="p-inputgroup">
                                                    <span class="p-inputgroup-addon" >
                                                        <i class="pi pi-pencil"></i>
                                                    </span>
                                                    <input pInputText [(ngModel)]="image.description" />
                                                    <input pInputText type="text" class="page_order" [inputStyle]="{'width':'60px'}" [style]="{'width': '60px'}" [(ngModel)]="image.image_order">

                                                </div>


                                    
                                            </ng-template>
                                        </p-cellEditor>
                                  
                                    </td>
                                   
                                    <td class="action">
                                        <button pButton pRipple icon="pi pi-trash"
                                        class="p-button p-button-danger p-mr-2"
                                        (click)="onDeleteImage(this.image.image_id, this.image.image_name, this.image.page_id)"></button>
                             
                                        </td>
                                </tr>
                            </ng-template>
                        </p-table>

                    </div>
                </div>
            </div>
        </div>

        <div class="p-col-12 p-md-4">
<div class="well">
            <div *ngFor="let progressInfo of progressInfos" class="mb-2">
                <span>{{ progressInfo.fileName }}</span>
                <p-progressBar [value]="progressInfo.value"></p-progressBar>

            </div>



            <div class="row">
                <div class="col-8">
                    <div class="button_outer_big">
                        <div class="btn_upload">
                            <input type="file" accept="image/*" multiple (change)="selectFiles($event)" />
                            Carica Immagine
                        </div>
                    </div>
                </div>

                <div class="col-4">
                    <button pButton class="p-button p-button-success p-mr-2" [disabled]="!selectedFiles"
                        (click)="uploadFiles()">
                        Upload
                    </button>
                    <br><br>
                </div>
            </div>


            <div *ngIf="message.length" class="alert alert-secondary my-3" role="alert">
                <div *ngFor="let msg of message; let i = index" class="p-message p-message-success">
                    <div class="p-message-wrapper">
                        <span class="p-message-summary">{{ msg }}</span>

                    </div>
                </div>
            </div>

            <div class="p-grid">
                 <div *ngFor="let preview of previews" class="p-col-6">
                
          
                        <img [src]="preview" class="img-responsive">
                    
                </div>

            </div>
        </div>
    </div>
    </div>
</p-card>