import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpParams } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { environment } from '../../environments/environment';
import { CrudService } from './crud.service';
import { Tours } from '../models/tours';

@Injectable({
  providedIn: 'root'
})

export class ToursService extends CrudService<Tours, number> {

  constructor(protected _http: HttpClient) {
    super(_http, `${environment.baseUrl}/tours`);
  }

 


}



