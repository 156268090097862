import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { Blog } from '../../../models/blog';
import { ConfirmationService, SelectItem } from 'primeng/api';
import { CategoryService } from '../../../services/categories.service';
import { Category } from '../../../models/category';
import { MessageService } from 'primeng/api';
import { Clients } from 'src/app/models/clients';
import { Works } from 'src/app/models/works';
import { Locations } from 'src/app/models/locations';
import { formatDate } from '@angular/common';
import jsPDF from "jspdf";
import "jspdf-autotable";
import { PARAM_ANIMATIONS_PATH, PARAM_PRODUCTS_PATH, STATUS_PRODUCTS } from '../../constants/constants';
import { Table } from 'primeng/table';
import { NgxSpinnerService } from "ngx-spinner";
import { TagsService } from 'src/app/services/tags.service';
import { Tags } from 'src/app/models/tags';
import { KeyValue } from '@angular/common';
import { ActivatedRoute } from '@angular/router';
import * as FileSaver from "file-saver";
import { FeaturesPersonsService } from 'src/app/services/features_persons.service';
import { ToursService } from 'src/app/services/tours.service';
import { Tours } from 'src/app/models/tours';
import { DatesService } from 'src/app/services/dates.service';

@Component({
  selector: 'app-manage-tours',
  templateUrl: './manage-tours.component.html'
})
export class ManageToursComponent implements OnInit {
  blogs: Blog;
  blog: Blog;
  dates:  any = [];

  works: any = [];
  work: Works;
  tours:  any = [];
  tour: Tours;
  animationsExport: Tours[];
  locations: any = [];
  location: Locations;
  cols: any[];
  colsData: any[];
  colsVariations: any[];
  colsDataVariations: any[];
  exportColumns: any[];
  _selectedColumns: any[];
  selectedWorks: any[];
  selectedSkills: any[];
  selectedSkillsVariations: any[];

  brands: any = [];
  featuredPersons:  any = [];
  tags: any = [];
  tag: Tags;
  filterSidebar: boolean = false;
  loading: boolean;
  currentIndex = 1;
  productsData: any = [];
  date: Date;
  skillsArray: any = [];
  categories: any = [];
  category: Category;
  error: string;
  private id: number;
  clients: any = [];
  client: Clients;
  productDialog: boolean = false;
  works_id: any;
  category_id: any;
  status: any;
  currentUser: any;
  productsVariations: any;
  technical_datas: any = [];
  skills: any[] = [];
  batches: any[];
  page = 1;
  count = 0;
  pageSize = 10;
  pageSizes = [5, 10, 15];
  public base_path: string;
  basePath: string;
  pageOfItems: Array<any>;
  searchWrapper: boolean = false;
  nameFilter: string;
  descriptionFilter: string;
  codeFilter: string;
  codeIntFilter: string;
  brandFilter: string;
  curatorFilter: any;
  orderBy: string;
  orderByType: string;
  tipologyFilter: string;
  destinationFilter: string;
  areaFilter: string;
  showDialog() {
    this.productDialog = true;
  }
  _start;
  _limit;
  myDate = formatDate(new Date(), 'dd/MM/yyyy', 'en');





  @ViewChild("dt", { static: false }) public dt: Table;

  constructor(
    private toursService: ToursService,
    private messageService: MessageService,
    private tagsService: TagsService,
    private spinner: NgxSpinnerService,
    private datesService: DatesService,
    private route: ActivatedRoute,
    private featuresPersonsService: FeaturesPersonsService,
    private confirmationService: ConfirmationService,) {
    this.status = STATUS_PRODUCTS;
    this.currentUser = JSON.parse(localStorage.getItem('currentUser') || '[]');


    this.basePath = window.location.pathname;
    if (this.route.snapshot.queryParamMap.has('page')) {
      this.page = +this.route.snapshot.queryParamMap.getAll('page');
    }
    if (this.route.snapshot.queryParamMap.has('size')) {
      this.pageSize = +this.route.snapshot.queryParamMap.getAll('size');
    }
    if (this.route.snapshot.queryParamMap.has('name')) {
      this.curatorFilter = this.route.snapshot.queryParamMap.get('name');
    }
    if (this.route.snapshot.queryParamMap.has('description')) {
      this.descriptionFilter = this.route.snapshot.queryParamMap.get('description');
    }

  }



  ngOnInit() {
    const userId = this.currentUser.user_id;
    this.spinner.show();

    
    this.load();
    this.cols = [
      { field: "page_title", header: "Titolo" },
     
      { field: "is_active", header: "Attivo" },

      
      
    ];

    this._selectedColumns = this.cols;
    this.exportColumns = this.cols.map(col => ({
      title: col.header,
      dataKey: col.field
    }));


    this.spinner.hide();


  }



  getRequestParams(searchTitle: string, categoryTitle: string, codeTitle: string, codeIntTitle: string, page: number, pageSize: string | number, orderBy: string, orderByType: string): any {
    // tslint:disable-next-line:prefer-const
    let path = PARAM_ANIMATIONS_PATH;
    let params: any = {};
    let adder = '?';
    if (page) {
      params[`page`] = page - 1;
      path += adder + 'page=' + (page - 1);
      adder = '&';
    }
    if (searchTitle) {
      params[`name`] = searchTitle;
      path += adder + 'name=' + searchTitle;
      adder = '&';
    }
    if (categoryTitle) {
      params[`description`] = categoryTitle;
      path += adder + 'description=' + categoryTitle;
      adder = '&';
    }

    if (codeTitle) {
      params[`code`] = codeTitle;
      path += adder + 'code=' + codeTitle;
      adder = '&';
    }

    if (codeIntTitle) {
      params[`code_int`] = codeIntTitle;
      path += adder + 'code_int=' + codeIntTitle;
      adder = '&';
    }


    if (pageSize) {
      params[`size`] = pageSize;
      path += adder + 'size=' + pageSize;
    }

    if (orderBy) {
      params[`orderBy`] = orderBy;
      adder + 'orderBy=' + orderBy;
    }

    if (orderByType) {
      params[`orderByType`] = orderByType;
      adder + 'orderByType=' + orderByType;
    }


    window.history.replaceState({}, '', path);
    return params;

  }

  reset(): void {
    this.nameFilter = '';
    this.descriptionFilter = '';
    this.codeFilter = '';
    this.codeIntFilter = '';
    this.brandFilter = '';
    this.load();
  }




  load(): void {
    const params = this.getRequestParams(
      this.nameFilter,
      this.descriptionFilter,
      this.destinationFilter,
      this.areaFilter,
      this.page,
      this.pageSize,
      this.orderBy = 'desc',
      this.orderByType = 'page_id',
    );
    this.toursService.getAllListNew(params).subscribe((pData) => {
      this.tours = pData;
      this.count = this.toursService.size;

    });
  
  }



  public handlePageChange(event): void {
    this.page = event;
    this.load();

  }




  edit(tour: Tours) {
    this.spinner.show();
    this.tour = { ...tour };
    this.productDialog = true;

    this.datesService.getAllListNew({tour_id: this.tour.id}).subscribe((pData) => {
      this.dates = pData;
      this.count = this.toursService.size;
    });

    this.spinner.hide();

  }



exportExcel() {

  import("xlsx").then(xlsx => {
      const worksheet = xlsx.utils.json_to_sheet(this.tours);
      const workbook = { Sheets: { 'data': worksheet }, SheetNames: ['data'] };
      const excelBuffer: any = xlsx.write(workbook, { bookType: 'xlsx', type: 'array' });
      this.saveAsExcelFile(excelBuffer, "animations");
  });
}

saveAsExcelFile(buffer: any, fileName: string): void {
  let EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
  let EXCEL_EXTENSION = '.xlsx';
  const data: Blob = new Blob([buffer], {
      type: EXCEL_TYPE
  });
  FileSaver.saveAs(data, fileName + '_export_' + formatDate(new Date(), 'dd/MM/yyyy_hh:mm', 'en') + EXCEL_EXTENSION);
}


  hideDialog() {
    this.productDialog = false;
  }

  onDelete(id: number, title: string) {

    this.confirmationService.confirm({
      message: 'Sei sicuro di volerlo cancellare = ' + id,
      header: 'Confirmation',
      icon: 'pi pi-exclamation-triangle',
      accept: () => {
        this.toursService.delete(+id).subscribe(
          res => {
            console.log(res);
            this.ngOnInit();
            this.messageService.add({ key: 'myKey1', severity: 'warn', summary: 'Attenzione', detail: 'Cancellazione avvenuto con successo' });

          },
          error => this.error = error
        );
      },

    });


  }

}
