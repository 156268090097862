import { Component, OnInit, ViewChild } from '@angular/core';

import { BlogService } from '../../services/blog.service';
import { Blog } from '../../models/blog';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { ANIMATIONS_SETTINGS, CLIENT_SETTING } from '../constants/constants';
import { CategoryService } from 'src/app/services/categories.service';
import { Category } from 'src/app/models/category';
import { Router, ActivatedRoute } from '@angular/router';
import {MessageService} from 'primeng/api';
import { ClientsService } from 'src/app/services/clients.service';
import { Clients } from 'src/app/models/clients';
import { ComuniService } from 'src/app/services/comuni.service';
import { Appointments } from 'src/app/models/appointments';
import { AppointmentsService } from 'src/app/services/appointments.service';
import { CalendarComponent } from 'ng-fullcalendar';

@Component({
  selector: 'app-settings',
  templateUrl: './settings.component.html'
})
export class SettingsComponent implements OnInit {

  calendarOptions: any;
  events: any;
  appointments: Appointments;
  error: string;
  blogForm: UntypedFormGroup;
  typeList: any;
  settingsPages: any;
  cities: Blog[];
  format1: string = "";
  format2: string = "";
  selectedCity: Blog;
  uploadError: string;
  selectedCategories: Category;
  selectedDate: Date;
  date: Date;
  categories: any = [];
  category: Category;
  productDialog:boolean = false;
  appointmentsDialog: boolean = false;
  clients: any = [];
  client: Clients;
  comuni: any = [];
  clientSettings: any = [];
  animationsSettings: any = [];
  itemsProduct: any = [];
  itemsCompany: any = [];

trackByFn(index, item) {
  return item.id;
}

@ViewChild(CalendarComponent) ucCalendar: CalendarComponent;

  constructor(private blogService: BlogService,     
    private clientsService: ClientsService,
    private appointmentsService: AppointmentsService,
    private fb: UntypedFormBuilder,
    private comuniService: ComuniService,
    private categoryService: CategoryService, 
    private router: Router,
    private messageService: MessageService,
    private route: ActivatedRoute
    ) {
      this.itemsProduct = [];
      this.itemsCompany = [];

   }

  ngOnInit() {
    this.clientSettings = CLIENT_SETTING;
    this.animationsSettings = ANIMATIONS_SETTINGS;
} 

  
}
