import { Component, NgZone, OnInit } from "@angular/core";
import { LANG_IT, PAGES } from "../constants/constants";
import { NgxSpinnerService } from "ngx-spinner";
import { SupportsService } from "src/app/services/supports.service";
import { AuthService } from "src/app/auth/auth.service";
import { formatDate } from "@angular/common";
import { Subject } from "rxjs";
import { MenuItem, PrimeNGConfig } from "primeng/api";
import { event } from "jquery";

@Component({
  selector: "app-admin",
  templateUrl: "./admin.component.html",
})
export class AdminComponent implements OnInit {
  currentUser: any;
  currentLang: string;
  submitted = false;
  returnUrl: string;
  error: {};
  loginError: string;
  username: string;
  user_id: number;
  flag = true;
  display = false;
  light = true;
  items: MenuItem[];
  account: any;
  password: string;
  first_name: string;
  last_name: string;
  pages: any[];
  supportsCount: any;
  myDate = formatDate(new Date(), "dd/MM/yyyy", "en");
  notify$ = new Subject();

  constructor(
    private spinner: NgxSpinnerService,
    private authService: AuthService,
    public primengConfig: PrimeNGConfig,
    private zone: NgZone
  ) {
    this.currentUser = JSON.parse(localStorage.getItem("currentUser") || "[]");

    this.currentLang = this.currentUser.lang;

    this.pages = PAGES;

    localStorage.setItem(
      "expiredDate",
      this.addMinutes(new Date(), 60).getTime().toString()
    );
    this.zone.runOutsideAngular(() => {
      const i = setInterval(() => {
        const expiredDate = +localStorage.getItem("expiredDate");
        console.log(new Date().getTime() - expiredDate);

        if (new Date().getTime() - expiredDate > 0) {
          this.zone.run(() => {
            this.notify$.next(event);
          });
          clearInterval(i);
        }
      }, 60000);
    });
  }

  ngOnInit() {


    this.items = [
      {
        label: "Dashboard",
        icon: "pi pi-pw pi-inbox",
        routerLink: "/admin",

      },
      {
        label: "Pagine",
        icon: "pi pi-fw pi-list",
        routerLink: "/admin/pages/",

      },
      {
        label: "News",
        icon: "pi pi-fw pi-table",
        routerLink: "/admin/news/",

      },

      {
        label: "Tours",
        icon: "pi pi-fw pi-book",
        routerLink: "/admin/tours/",

      },

      {
        label: "Richieste Tour",
        icon: "pi pi-fw pi-calendar",
        routerLink: "/admin/tours-requests/",

      },

      {
        label: "Configurazioni",
        icon: "pi pi-fw pi-cog",
        routerLink: "/admin/settings/",
      },

      {
        label: "Utenti",
        icon: "pi pi-fw pi-user",
        routerLink: "/admin/users/",

      },

      {
        label: "Logout",
        icon: "pi pi-sign-in",
        command: (event) => {
          this.logout();
        },
      },
    ];

    this.notify$.subscribe(() => {
      localStorage.removeItem("expiredDate");
      this.logout();
    });

    this.spinner.show();

    setTimeout(() => {
      this.spinner.hide();
    }, 500);
  } 

  check_account() {

  const userToken = this.currentUser.token;
  this.authService.check_account(userToken).subscribe((pData) => {
    this.account = pData;
    console.log(this.account)

    if (this.account === false) {
      this.authService.logout();
    }
  });
}

  addMinutes(date, minutes) {
    return new Date(date.getTime() + minutes * 60000);
  }

  logout() {
    this.authService.logout();
  }

  
  getInitialsTextColor(firstName: string, lastName: string): string {
    var s = this.getSaturationByName(firstName, lastName);
    var l = this.getLightnessByName(firstName, lastName);
    if (l >= 40) return "black";
    else return "white";
  }
  getLightnessByName(firstName: string, lastName: string): number {
    var num =
      firstName.toUpperCase().charCodeAt(0) +
      lastName.toUpperCase().charCodeAt(0);
    return this.scaleBetween(num, 5, 95, 65 * 2, 97 * 2);
  }
  getSaturationByName(firstName: string, lastName: string): number {
    var num =
      firstName.toUpperCase().charCodeAt(0) +
      lastName.toUpperCase().charCodeAt(0);
    return this.scaleBetween(num, 30, 75, 65 * 2, 97 * 2);
  }
  scaleBetween(unscaledNum, minAllowed, maxAllowed, min, max): number {
    var res =
      ((maxAllowed - minAllowed) * (unscaledNum - min)) / (max - min) +
      minAllowed;
    return parseInt(res);
  }
  stringToHslColor(str: string, saturation: number, lightness: number): string {
    var hash = 0;
    for (var i = 0; i < str.length; i++) {
      hash = str.charCodeAt(i) + ((hash << 5) - hash);
    }
    var h = hash % 360;
    return "hsl(" + h + ", " + saturation + "%, " + lightness + "%)";
  }

  
}
