import { Component, OnInit, ViewChild } from '@angular/core';
import { ConfirmationService, MenuItem } from 'primeng/api';
import { CategoryService } from '../../../services/categories.service';
import { Category } from '../../../models/category';
import { MessageService } from 'primeng/api';
import { ComuniService } from 'src/app/services/comuni.service';
import { Comuni } from 'src/app/models/comuni';
import jsPDF from "jspdf";
import "jspdf-autotable";
import { NgxSpinnerService } from "ngx-spinner";
import { PARAM_CLIENTS_PATH, PARAM_EMPLOYEES_PATH, PARAM_NEWS_PATH, PARAM_PAGES_PATH, TOURS_REQUEST_API } from '../../constants/constants';
import { NewsService } from 'src/app/services/news.service';
import { News } from 'src/app/models/news';
import { PagesService } from 'src/app/services/pages.service';
import { Pages } from 'src/app/models/pages';
import { ToursRequestsService } from 'src/app/services/tours-requests.service';
import { Requests } from 'src/app/models/requests';


@Component({
  selector: 'app-manage-tour-requests',
  templateUrl: './manage-tour-requests.component.html'
})
export class ManageTourRequestsComponent implements OnInit {
  requests: any = [];
  request: Pages;

  filterSidebar: boolean;
  categories: any = [];
  category: Category;
  error: string;
  comuni: any = [];
  cols: any[];
  exportColumns: any[];
  _selectedColumns: any[];
  loading: boolean;
  totalRecords: string;
  currentUser: any ;
  page = 1;
  count = 0;
  pageSize = 10;
  pageSizes = [5, 10, 15];
  public base_path: string;
  basePath: string;
  pageOfItems: Array<any>;
  searchWrapper: boolean = false;
  nameFilter: string;
  descriptionFilter: string;
  codeFilter: string;
  codeIntFilter: string;
  brandFilter: string;
  private category_id: number;
  private id: number;

  productDialog: boolean = false;


  showDialog() {
    this.productDialog = true;
  }


  trackByFn(index, item) {
    return item.id;
  }


  constructor(
    private messageService: MessageService,
    private spinner: NgxSpinnerService,
    private toursRequestsService: ToursRequestsService,
    private categoryService: CategoryService,
    private confirmationService: ConfirmationService,) {
      const doc = new jsPDF();

  }

  ngOnInit() {
    this.currentUser = JSON.parse(localStorage.getItem('currentUser') || '[]');
    const userId = this.currentUser.user_id;

   

    this.spinner.show();
      this.load();
      
      this.cols = [
        { field: "name", header: "Nome" },
        { field: "surname", header: "Cognome" },
        { field: "email", header: "email" },
        { field: "persons", header: "Persone" },

      ];

      this._selectedColumns = this.cols;
      this.exportColumns = this.cols.map(col => ({
        title: col.header,
        dataKey: col.field
      }));
      this.spinner.hide();


  }



  getRequestParams(searchTitle, categoryTitle, page, pageSize): any {
    // tslint:disable-next-line:prefer-const
    let path = TOURS_REQUEST_API;
    const params = {};
    let adder = '?';
    if (page) {
      params[`page`] = page - 1;
      path += adder + 'page=' + (page - 1);
      adder = '&';
    }
    if (searchTitle) {
      params[`name`] = searchTitle;
      path += adder + 'name=' + searchTitle;
      adder = '&';
    }
    if (categoryTitle) {
      params[`description`] = categoryTitle;
      path += adder + 'description=' + categoryTitle;
      adder = '&';
    }

    if (pageSize) {
      params[`size`] = pageSize;
      path += adder + 'size=' + pageSize;
    }
    window.history.replaceState({}, '', path);

    return params;

  }

  reset(): void {
    this.nameFilter = '';
    this.descriptionFilter = '';
    this.codeFilter = '';
    this.codeIntFilter = '';
    this.brandFilter = '';
    this.load();

  }

  load(): void {

    const params = this.getRequestParams(
      this.nameFilter,
      this.descriptionFilter,
      this.page,
      this.pageSize
    );
    this.toursRequestsService.getAllListNew(params).subscribe((pData) => {
      this.requests = pData;
      this.count = this.toursRequestsService.size;
    });
  }



  public handlePageChange(event): void {
    this.page = event;
    this.load();

  }

  public selectionItemForFilter(e) {
    const colsTempor = e.value;
    colsTempor.sort(function (a, b) {
      return a.index - b.index;
    });
    this.cols = [];
    this.cols = colsTempor;
    if (e.value.length > 10) {
      e.value.pop();
    }
  }



  edit(request: Requests) {
    this.request = { ...request };
    this.productDialog = true;
  }


  hideDialog() {
    this.productDialog = false;
  }

 



  onDelete(id: number, title: string) {

    this.confirmationService.confirm({
      message: 'Sei sicuro di volerlo cancellare',
      header: 'Confirmation',
      icon: 'pi pi-exclamation-triangle',
      accept: () => {
        this.toursRequestsService.delete(+id).subscribe(
          res => {
            console.log(res);
            this.ngOnInit();
            this.messageService.add({ key: 'myKey1', severity: 'success', summary: 'Attenzione', detail: 'Cancellazione avvenuto con successo' });

          },
          error => {
            this.error = error;
            this.messageService.add({ key: 'myKey2', severity: 'warn', summary: 'Attenzione', detail: 'Errore backend' });
          });
      },

    });


  }

}
