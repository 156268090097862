<p-sidebar #op [(visible)]="displayFilter" position="right" [style]="{width:'100%', 'max-width':'360px'}">
    <ng-template pTemplate>

        <div class="p-field p-grid">
            <div class="p-col-12">

                <input type="text" [style]="{ width: '100%' }" pInputText [(ngModel)]="nameFilter" placeholder="Nome" />
            </div>
        </div>
        <div class="p-field p-grid">
            <div class="p-col-12">
                <input type="text" [style]="{ width: '100%' }" pInputText [(ngModel)]="descriptionFilter"
                    placeholder="Descrizione" />
            </div>
        </div>

        <div class="p-field p-grid">
            <div class="p-col-6">
                <button pButton type="reset" class="p-button p-button-secondary btn-block" label="annulla"
                    (click)="reset()">
                </button>
            </div>

            <div class="p-col-6">
                <button pButton type="submit" class="p-button p-button-primary btn-block" label="cerca"
                    (click)="load()">
                </button>
            </div>

        </div>

    </ng-template>
</p-sidebar>



<div class="top-main-wrapper">
    <div class="dash-header">
        <div class="dash-title">

            <div class="p-field p-grid">
                <div class="p-col-12 p-md-4">
                    <h3>{{title}}</h3>
                </div>
                <div class="p-col-12 p-md-8">
                    <button pButton pRipple icon="pi pi-plus"
                        [routerLink]="['/admin/settings/features-technical-data/create']" label=" aggiungi"
                        class="p-button p-button-primary p-mr-2 pull-right"></button>
                    <button type="text" pButton label="Filter" icon="pi pi-filter"
                        class="p-button p-button-options p-mr-2 pull-right" (click)="displayFilter = true"></button>

                    <button type="button" pButton pRipple icon="pi pi-chevron-left" label=" indietro"
                        class="p-button p-button-back pull-right  p-mr-2" [routerLink]="['/admin/settings/']"></button>
                    <!-- <button type="button" pButton pRipple icon="pi pi-file-pdf" label="Esporta"
                        (click)="exportPdf()" class="p-button-options p-mr-2 pull-right"
                        tooltipPosition="bottom"></button>-->
                </div>
            </div>

        </div>
    </div>

</div>

<div class="main-inner">


    <div class="p-fluid">
        <div class="p-field p-grid list">
            <div class="p-col-12 p-md-12">



                <div class="filter-options" *ngIf="nameFilter">

                    <div class="p-field p-grid">

                        <div class="p-col-12 p-md-12">
                            <div class="item-size pull-left">
                                <span class="filter-tag" *ngIf="nameFilter">{{nameFilter}} <span class="close"><i
                                            class="pi pi-times" (click)="reset()"></i></span> </span>
                                <span class="filter-tag" *ngIf="descriptionFilter">{{descriptionFilter}} <span
                                        class="close"><i class="pi pi-times" (click)="reset()"></i></span> </span>
                            </div>
                        </div>
                    </div>
                </div>
                <div
                    *ngFor=" let category of categories  | paginate: { itemsPerPage: pageSize, currentPage: page, totalItems: count }; let i = index">
                </div>

                <p-table #dt [columns]="cols" [value]="categories" [paginator]="false">

                    <ng-template pTemplate="header" let-columns>
                        <tr>
                            <th *ngFor="let col of cols">
                                {{col.header}}
                            </th>
                            <th width="140"></th>
                        </tr>

                    </ng-template>
                    <ng-template pTemplate="body" let-rowData let-columns="columns">
                        <tr>
                            <td *ngFor="let col of cols">
                                {{rowData[col.field]}}
                            </td>

                            <td class="action">
                                <button pButton pRipple icon="pi pi-pencil" class="p-button p-button-success p-mr-2"
                                    [routerLink]="['/admin/settings/features-technical-data/edit', rowData.id]"></button>
                                <button pButton pRipple icon="pi pi-trash" class="p-button p-button-danger p-mr-2"
                                    (click)="onDelete(rowData.id)"></button>
                            </td>
                        </tr>
                    </ng-template>
                </p-table>

                <div class="p-grid">
                    <div class="p-col-12 p-md-4">
                        <p class="p-pt-1">
                            <span class="count-item pull-left">{{pageSize * page - pageSize +1 }} - {{pageSize * page}}
                                di {{count}} risultati</span>
                        </p>
                    </div>
                    <div class="p-col-12 p-md-8">
                        <pagination-controls previousLabel="Prev" nextLabel="Next" responsive="true"
                            (pageChange)="handlePageChange($event)">
                        </pagination-controls>
                    </div>
                </div>

            </div>
        </div>
    </div>

</div>

<p-confirmDialog [style]="{width: '50vw'}" [baseZIndex]="10000" rejectButtonStyleClass="p-button-text" #cd>

    <p-footer>
        <button type="button" pButton icon="fa fa-close" class="p-button p-button-danger p-mr-2" label="No"
            (click)="cd.reject()"></button>
        <button type="button" pButton icon="fa fa-check" class="p-button p-button-success p-mr-2" label="Si"
            (click)="cd.accept()"></button>
    </p-footer>

</p-confirmDialog>

{{error}}