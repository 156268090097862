

<app-topnav></app-topnav>

<div class="top-main-wrapper">


    <div class="dash-header">
        <div class="dash-title">
    
            <div class="p-field p-grid">
                <div class="p-col-6 p-md-6">
                    <h3>Richieste Tours</h3>
                </div>
                <div class="p-col-6 p-md-6">
    
                 
                </div>
            </div>
    
        </div>
    </div>
</div>



<div class="main-inner">





<div class="p-fluid">
    <div class="p-field p-grid">
        <div class="p-col-12 p-md-12">

                <div class="filter-options">

                    <div class="p-field p-grid">

                        <div class="p-col-12 p-md-8">
                            <div class="item-size pull-left">
                                <span class="filter-tag" *ngIf="nameFilter" >Nome: {{nameFilter}} <span class="close"><i class="pi pi-times"  (click)="reset()"></i></span> </span>
                                <span class="filter-tag" *ngIf="descriptionFilter">Cognome: {{descriptionFilter}} <span class="close"><i class="pi pi-times" (click)="reset()"></i></span> </span>
                            
                            </div>
                        </div>
                    </div>
                </div>

                    <div *ngFor=" let request of requests  | paginate: { itemsPerPage: pageSize, currentPage: page, totalItems: count }; let i = index"></div>

                <p-table #dt [columns]="cols" [value]="requests" [responsive]="true" [autoLayout]="true"  [paginator]="false" [rows]="100">

                    <ng-template pTemplate="body" let-rowData let-columns="columns">
                            <tr *ngIf="rowData">
                                <td>
                          
                                    {{rowData['date'] | date: 'dd-MM-yyyy'}}
                            
                            </td>
                            
                            <td *ngFor="let col of cols">
                                    {{rowData[col.field]}}

                                </td>
                                
                            <td class="action">
                                <button pButton pRipple icon="pi pi-trash" class="p-button p-button-danger pull-right p-mr-2"
                                (click)="onDelete(rowData.id)"></button>

                    
                            <button pButton pRipple icon="pi pi-eye" class="p-button p-button-primary pull-right p-mr-2"
                                (click)="edit(rowData)"></button>

                    
                                              
                            </td>
                        </tr>

                        
                    </ng-template>
                    
                </p-table> 
                <div class="p-grid">
                    <div class="p-col-12 p-md-4">
                        <p class="p-pt-1">
                            <span class="count-item pull-left">{{pageSize * page - pageSize +1 }} - {{pageSize * page}} di {{count}} risultati</span></p></div>
                    <div class="p-col-12 p-md-8">
                        <pagination-controls previousLabel="Prev" nextLabel="Next" responsive="true"
                            (pageChange)="handlePageChange($event)">
                        </pagination-controls>
                    </div>
                </div>
              
     
            </div>
        </div>
    </div>
</div>

<p-sidebar [(visible)]="productDialog" position="right"  [style]="{width:'50%'}">
    <ng-template pTemplate="content">
        <div class="p-col-12 p-md-12">
            <p>Richiesta in data {{request.date_created | date: 'dd-MM-yyyy - H:mm'}}</p>

          <h3>{{request.name}} {{request.surname}}</h3>
            <hr class="full">
            <p [innerHTML]="request.email"></p>
            <p [innerHTML]="request.address"></p>
            <p>{{request.date | date: 'dd-MM-yyyy'}}</p>
            <p [innerHTML]="request.persons"></p>
            <p-tag>{{request.tour}}</p-tag>

   
   </div>
    </ng-template>
</p-sidebar>




<p-confirmDialog [style]="{width: '50vw'}" [baseZIndex]="10000" rejectButtonStyleClass="p-button-text" #cd>

    <p-footer>
        <button type="button" pButton icon="fa fa-close" class="p-button p-button-danger p-mr-2" label="No" (click)="cd.reject()"></button>
        <button type="button" pButton icon="fa fa-check" class="p-button p-button-success p-mr-2" label="Si" (click)="cd.accept()"></button>
    </p-footer>

</p-confirmDialog>

<p-toast key="myKey1"></p-toast>

<p-toast key="myKey2">{{error}}</p-toast>



