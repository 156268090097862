import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpParams } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError, delay, map } from 'rxjs/operators';
import { environment } from '../../environments/environment';
import { CrudService } from './crud.service';
import { Tours } from '../models/tours';

@Injectable({
  providedIn: 'root'
})

export class SeoService extends CrudService<Tours, number> {

  constructor(protected _http: HttpClient) {
    super(_http, `${environment.baseUrl}/seo`);
  }

  seoChecker(pars: any, id: number): Observable<any> {
    let queryParams = new HttpParams();
    queryParams = queryParams.append("name",pars);
    queryParams = queryParams.append("id", id);
    return this._http.get<any>(this._base + '/', {params:queryParams});
  }

}



