import { Component, ElementRef, OnInit } from '@angular/core';
import { ClientsService } from '../../../services/clients.service';
import { UntypedFormBuilder, Validators, UntypedFormGroup, UntypedFormArray } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { ViewChild } from '@angular/core';
import { Clients } from '../../../models/clients';
import { Category } from '../../../models/category';
import { UntypedFormControl } from '@angular/forms';
import { CategoryService } from '../../../services/categories.service';
import { ConfirmationService, MessageService, SelectItem } from "primeng/api";
import * as moment from 'moment';
import { FISCAL_CODE_VALIDATOR_REGEX, SEX_LIST, STATE_LIST, BUSINESS_STATE_LIST, STATE_PUBLISHED } from '../../constants/constants';
import { ComuniService } from 'src/app/services/comuni.service';
import { Comuni } from 'src/app/models/comuni';
import {Location} from '@angular/common';
import { Billings } from 'src/app/models/billings';
import { Personal_data } from 'src/app/models/personal_data';
import { NgxSpinnerService } from "ngx-spinner";
import { NewsService } from 'src/app/services/news.service';
import { PagesService } from 'src/app/services/pages.service';
import { Pages } from 'src/app/models/pages';
import { FeaturesTechnicalDataService } from 'src/app/services/features_technical_data';



@Component({
  selector: "app-pages-form",
  templateUrl: "./pages-form.component.html",
})

export class PagesFormComponent implements OnInit {
  @ViewChild("myInput", { static: false }) myInputVariable: ElementRef;

  pageTitle: string;
  error: string;
  uploadError: string;
  imagePath: any;
  clients: Clients;
  client: Clients;


  clientsList: any = [];
  clientList: Clients;


  categories: any = [];
  category: Category;
  checked: boolean = true;
  selectedValue: string;
  comuni: Comuni;
  page = 1;
  count = 0;
  pageSize = 10;
  pageSizes = [5, 10, 15];
  blogForm: UntypedFormGroup;
  typeList: any[];
  featuresTechnicalData: Category[] = [];
  nameFilter: string;
  descriptionFilter: string;
  codeFilter: string;
  codeIntFilter: string;
  brandFilter: string;
  cities: Clients[];
  format1: string = "";
  format2: string = "";
  selectedCity: Clients;
  selectedCategories: Category;
  selectedDate: Date;
  selectedPagesPublished: any[];
  date: Date;
  appointments: any = [];
  is_featured = '0';
  deleteButton: boolean;
  statePublished: any[];
  currentUser: any;
  addForm: UntypedFormGroup;
  rows: UntypedFormArray;
  itemForm: UntypedFormGroup;
  skillsForm: UntypedFormGroup;
  skillsValues: any = [];
  stateOptions: any[];
  cols: any[];
  exportColumns: any[];
  selectedStatePublished: SelectItem[] = [];
  _selectedColumns: any[];
  businessStateOptions: any[]; 
  personal_datas: any = [];
  personal_data: Personal_data;
  personName: string;
  element: Pages;
  id: number;
  pagess: any = [];
  pages: Pages;


  trackByFn(index, item) {
    return item.id;
  }

  constructor(
    private fb: UntypedFormBuilder,
    private clientsService: ClientsService,
    private messageService: MessageService,
    private pagesService: PagesService,
    private _location: Location,
    private spinner: NgxSpinnerService,
    private featuresTechnicalDataService: FeaturesTechnicalDataService,
    private confirmationService: ConfirmationService,
    private router: Router,
    private route: ActivatedRoute
  ) {
    if (this.date) {
      this.selectedDate = new Date(this.date);
    }
    this.currentUser = JSON.parse(localStorage.getItem('currentUser') || '[]');
    this.stateOptions = STATE_LIST;
    this.statePublished = STATE_PUBLISHED;
    const userId = this.currentUser.user_id;


  }

  ngOnInit() {

    this.spinner.show();


    const id = this.route.snapshot.paramMap.get("id");

    if (id) {
      this.pageTitle = "Modifica Pagina";
      this.deleteButton = true;
      
  
      this.pagesService.getId(+id).subscribe((res) => {
        this.element = res
        

        this.blogForm.patchValue({
          page_title: res.page_title,
          description_1: res.description_1,
          description_2: res.description_2,
          is_active: res.is_active,
          status: res.status,
          page_seotitle: res.page_seotitle,
          page_seokeywords: res.page_seokeywords,
          page_seodescription: res.page_seodescription,
          ref_id: res.ref_id,
          page_url: res.page_url,
          id: res.id,
          skills: this.skillsValues,
        });
       
        this.imagePath = res.image;
        this.id = res.id
      });
    } else {
      this.deleteButton = false;
      this.pageTitle = "Aggiungi Pagina";
    }




    this.blogForm = this.fb.group({
      id: [""],
      page_title: ["", Validators.required],
      description_1: [""],
      description_2: [""],
      page_seotitle: [""],
      status: [""],
      ref_id: [""],
      page_seokeywords: [""],
      page_seodescription: [""],
      page_url: ["", Validators.required],
      is_active: ["0"],
      skills: this.initSkill(),
    });

    this.blogForm.get("page_title").valueChanges.subscribe(val => {
      this.blogForm.patchValue({ page_url: val });
    });
    
    this.featuresTechnicalDataService.getAllList().subscribe((pData) => {
      this.featuresTechnicalData = pData;
    });

    this.getPages();

    setTimeout(() => {

      this.spinner.hide();
  }, 200);
 


  }
  



  removeQuantity(i:number) {
    
    this.skills.removeAt(i);
  }
  
  getCategoryItem(category_id: string, id: string) {
    return this.categories.find((item) => item.id === category_id);
  }

  

  initSkill() {
    var formArray = this.fb.array([]);
    const id = this.route.snapshot.paramMap.get("id");

    this.pagesService.skills(+id).subscribe(
      (res)=>{
        this.skillsValues = res;
        this.skillsValues.forEach((e)=>{
          formArray.push(this.fb.group({
            label: [e.label],
            value: [e.value],
          }))
        })
      }
    )

    /*formArray.push(this.fb.group({
      qty: [''],
      price: ['']
    })) */
    

    return formArray;
  }

   
  private createSkillFormGroup(skill: any): UntypedFormGroup {
    return new UntypedFormGroup({
      label: new UntypedFormControl(skill.label),
      value: new UntypedFormControl(skill.value),
    });
  }

  public addSkill(skill: any) {
    this.skills.push(this.createSkillFormGroup(skill));
  }

  get skills() {
    return this.blogForm.get('skills') as UntypedFormArray;
  }
   

 
  newQuantity(): UntypedFormGroup {
    return this.fb.group({
      label: "",
      value: "",
    });
  }
   
  addQuantity() {
    this.skills.push(this.newQuantity());
  }
  


  getRequestParams(searchTitle, categoryTitle, page, pageSize): any {
    // tslint:disable-next-line:prefer-const
    const params = {};
    let adder = '?';
    if (page) {
      params[`page`] = page - 1;
      adder + 'page=' + (page - 1);
      adder = '&';
    }
    if (searchTitle) {
      params[`name`] = searchTitle;
      adder + 'name=' + searchTitle;
      adder = '&';
    }
    if (categoryTitle) {
      params[`description`] = categoryTitle;
      adder + 'description=' + categoryTitle;
      adder = '&';
    }

    if (pageSize) {
      params[`size`] = pageSize;
      adder + 'size=' + pageSize;
    }

    return params;

  }

  getPages(): void {

    const params = this.getRequestParams(
      this.nameFilter,
      this.descriptionFilter,
      this.page,
      this.pageSize = 100
    );
    this.pagesService.getAll(params).subscribe((pData) => {
      this.pagess = pData;
      this.count = this.pagesService.size;
    });
  }


  onSubmit() {
    const formData = new FormData();
    formData.append("page_title", this.blogForm.get("page_title").value);
    formData.append("page_url", this.blogForm.get("page_url").value);
    formData.append("is_active", this.blogForm.get("is_active").value);
    formData.append("status", this.blogForm.get("status").value);
    formData.append("description_1", this.blogForm.get("description_1").value);
    formData.append("description_2", this.blogForm.get("description_2").value);
    formData.append("page_seodescription", this.blogForm.get("page_seodescription").value);
    formData.append("page_seokeywords", this.blogForm.get("page_seokeywords").value);
    formData.append("page_seotitle", this.blogForm.get("page_seotitle").value);
    formData.append('skills', JSON.stringify(this.blogForm.get('skills').value));
    formData.append("ref_id", this.blogForm.get("ref_id").value);

    const id = this.blogForm.get("id").value;

    if (id) {
      this.pagesService.update(formData, +id).subscribe(
        (res) => {
          if (res.status == "error") {
            this.uploadError = res.message;
            this.messageService.add({ key: 'myKey1', severity: 'warn', summary: 'Attenzione', detail: 'Salvato senza sucesso' })

          } else {
            this.messageService.add({ key: 'myKey1', severity: 'success', summary: 'Attenzione', detail: 'Salvato con sucesso' });
           // this._location.back();
          }
        },
        (error) => (this.error = error)
      );
    } else {
      this.pagesService.create(formData).subscribe(
        (res) => {
          if (res.status === "error") {
            this.uploadError = res.message;
          } else {
            this.messageService.add({ key: 'myKey1', severity: 'success', summary: 'Attenzione', detail: 'Salvato con sucesso' });
            this.router.navigate(['/admin/pages']);

          }
        },
        (error) => (this.error = error)
      );
    }
  }
}

