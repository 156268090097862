<app-topnav></app-topnav>

<form [formGroup]="blogForm">

    <div class="top-main-wrapper">

        <div class="dash-header">
            <div class="dash-title">

                <div class="p-field p-grid">
                    <div class="p-col-8 p-md-6">
                        <h3>Dettaglio Tours <span class="normal-font" *ngIf="id">/ {{tourTitle}}</span> </h3>
                    </div>
                    <div class="p-col-4 p-md-6">
                        <button pButton pRipple icon="pi pi-save" label="salva"
                            class="p-button pull-right p-button-success p-mr-2" (click)="onSubmit()"
                            [disabled]="!blogForm.valid"></button>


                        <button type="button" pButton pRipple icon="pi pi-chevron-left" label=" indietro"
                            class="p-button p-button-back pull-right  p-mr-2" [routerLink]="['/admin/tours/']"></button>
                    </div>
                </div>
            </div>
        </div>
    </div>


    <div class="main-inner">

        <div class="p-fluid">
            <div class="p-field p-grid">




                <div class="p-col-12 p-md-12">


                    <p-tabView>



                        <p-tabPanel header="Informazioni">
                            <div class="p-grid">

                                <div class="p-col-12 p-md-12">
                                    <p-card>
                                        <div class="p-grid">
                                            <div class="p-col-12 p-md-7">
                                                <div class="left-section-card">
                                                    <div class="p-fluid">


                                                        <div class="p-field p-grid">
                                                            <label class="p-col-12 p-mb-2">Nome<span
                                                                    class="required">*</span></label>
                                                            <div class="p-col-12 p-md-12">

                                                                <input type="text" [style]="{ width: '100%' }"
                                                                    pInputText formControlName="page_title">

                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div class="p-fluid">

                                                        <div class="p-field p-grid">
                                                            <label class="p-col-12 p-mb-2">Page url <span
                                                                    class="required">*</span></label>
                                                            <div class="p-col-12 p-md-12">
                                                                <input type="text" pInputText
                                                                    placeholder="Nome" formControlName="page_url" />
                                                            </div>

                                                        </div>
                                                    </div>




                                                    <div class="p-fluid">
                                                        <div class="p-field p-grid">
                                                            <label class="p-col-12 p-mb-2">Descrizione 1</label>
                                                            <div class="p-col-12 p-md-12">
                                                                <p-editor formControlName="description_1"
                                                                    [style]="{ height: '220px' }">
                                                                    <ng-template pTemplate="header">
                                                                        <span class="ql-formats">
                                                                            <button type="button" class="ql-bold"
                                                                                aria-label="Bold"></button>
                                                                            <button type="button" class="ql-italic"
                                                                                aria-label="Italic"></button>
                                                                            <button type="button" class="ql-underline"
                                                                                aria-label="Underline"></button>
                                                                                <button type="button" 
                class="ql-clean" 
                aria-label="clean"> 
            </button> 
       
            <button type="button" 
                class="ql-link" 
                aria-label="Link"> 
            </button>
                                                                        </span>
                                                                    </ng-template>
                                                                </p-editor>



                                                            </div>
                                                        </div>
                                                    </div>


                                                    <div class="p-fluid">
                                                        <div class="p-field p-grid">
                                                            <label class="p-col-12 p-mb-2">Descrizione 2</label>
                                                            <div class="p-col-12 p-md-12">
                                                                <p-editor formControlName="description_2"
                                                                    [style]="{ height: '220px' }">
                                                                    <ng-template pTemplate="header">
                                                                        <span class="ql-formats">
                                                                            <button type="button" class="ql-bold"
                                                                                aria-label="Bold"></button>
                                                                            <button type="button" class="ql-italic"
                                                                                aria-label="Italic"></button>
                                                                            <button type="button" class="ql-underline"
                                                                                aria-label="Underline"></button>
                                                                                <button type="button" 
                                                                                class="ql-clean" 
                                                                                aria-label="clean"> 
                                                                            </button> 
                                                                       
                                                                            <button type="button" 
                                                                                class="ql-link" 
                                                                                aria-label="Link"> 
                                                                            </button>
                                                                        </span>
                                                                    </ng-template>
                                                                </p-editor>



                                                            </div>
                                                        </div>
                                                    </div>



                                                    <div class="p-field p-grid">
                                                        <div class="p-col-12">
                                                            <input type="hidden" formControlName="id">
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="p-col-12 p-md-5">
                                                <div class="well">

                                                    <div class="p-fluid">
                                                        <div class="p-grid">
                                                            <div class="p-col-8">
                                                                <div class="p-grid">

                                                                    <label class="p-col-12 p-mb-2">Status <span
                                                                            class="required">*</span></label>
                                                                    <div class="p-col-12 p-md-12">
                                                                        <p-dropdown [options]="statePublished"
                                                                            formControlName="status"
                                                                            [(ngModel)]="selectedStatePublished"
                                                                            optionLabel="label" optionValue="value">

                                                                        </p-dropdown>

                                                                    </div>
                                                                </div>


                                                            </div>
                                                            <div class="p-col-4">
                                                                <div class="p-grid">

                                                                    <label class="p-col-12 p-mb-2">Attivo <span
                                                                            class="required">*</span></label>
                                                                    <div class="p-col-12 p-md-12">
                                                                        <p-selectButton [options]="stateOptions"
                                                                            formControlName="is_active"
                                                                            optionLabel="label"
                                                                            optionValue="value"></p-selectButton>
                                                                    </div>
                                                                </div>

                                                            </div>
                                                        </div>

                                                    </div>
                                                        <div class="p-grid p-mt-3">
                                                            <div class="p-col-12 p-md-4">
                                                                <div class="p-grid">

                                                                <label class="p-col-12 p-mb-2">Slider <span
                                                                        class="required">*</span></label>
                                                                <div class="p-col-12 p-md-12">
                                                                    <p-selectButton [options]="featuredOptions"
                                                                        formControlName="slider" optionLabel="label"
                                                                        optionValue="value"></p-selectButton>
                                                                </div>
                                                            </div>
                                                            
                                                            </div>
                                                            <div class="p-col-12 p-md-4">
                                                                <div class="p-grid">

                                                                <label class="p-col-12 p-mb-2">Homepage <span
                                                                        class="required">*</span></label>
                                                                <div class="p-col-12 p-md-12">
                                                                    <p-selectButton [options]="featuredOptions"
                                                                        formControlName="homepage" optionLabel="label"
                                                                        optionValue="value"></p-selectButton>
                                                                </div>
                                                            </div>
                                                            </div>
                                                        </div>


                                                </div>


                                            </div>

                                        </div>

                                    </p-card>
                                </div>
                            </div>



                        </p-tabPanel>

                        <p-tabPanel header="Date disponibili">


                            <div class="p-grid">

                                <div class="p-col-12 p-md-12">
                                    <p-card>
                                        <div class="p-grid">
                                            <div class="p-col-12 p-md-7">
                                                <div class="left-section-card">
                                                    <div class="p-fluid">

                                                        <div
                                                            *ngFor=" let date of dates  | paginate: { itemsPerPage: pageSize, currentPage: page, totalItems: count }; let i = index">
                                                        </div>


                                                        <p-table #dt [columns]="cols" [value]="dates" [rows]="10"
                                                            [responsive]="false" [autoLayout]="true"
                                                            [paginator]="false">

                                                            <ng-template pTemplate="header" let-columns>
                                                                <tr>
                                                                    <th *ngFor="let col of cols">
                                                                        {{col.header}}
                                                                    </th>
                                                                    <th width="200"></th>
                                                                </tr>

                                                            </ng-template>
                                                            <ng-template pTemplate="body" let-rowData
                                                                let-columns="columns">
                                                                <tr>



                                                                    <td *ngFor="let col of cols">


                                                                        <div *ngIf="col.field == 'date'">

                                                                            {{ rowData[col.field]  | date:'dd/MM/yyyy'  }}</div>

                                                                        <div *ngIf="col.field == 'time'">

                                                                            {{ rowData[col.field] }}</div>

                                                                    </td>

                                                                    <td class="action">
                                                                        <button pButton pRipple icon="pi pi-trash"
                                                                            class="p-button p-button-danger pull-right p-mr-2"
                                                                            (click)="onDelete(rowData.id)"></button>

                                                                    </td>
                                                                </tr>
                                                            </ng-template>
                                                        </p-table>
                                                        <pagination-controls previousLabel="Prev" nextLabel="Next" responsive="true"
                                                        (pageChange)="handlePageChange($event)">
                                                    </pagination-controls>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="p-col-12 p-md-5">
                                                <div class="well">

                                                    <form [formGroup]="datesForm">
                                                        <div class="p-fluid">
                                                            <div class="p-grid">
                                                                <label class="p-col-12 p-mb-2">Data <span
                                                                        class="required">*</span></label>
                                                                <div class="p-col-6">

                                                                    <p-calendar dataType="string"
                                                                        placeholder="12/12/2018" dateFormat="yy-mm-dd"
                                                                        formControlName="date"></p-calendar>
                                                                </div>
                                                                <div class="p-col-6">

                                                                    <p-inputMask mask="99:99" formControlName="time"
                                                                        inputId="date"
                                                                        placeholder="09:00"></p-inputMask>



                                                                    <div class="p-col-12">

                                                                        <input type="hidden" formControlName="tour_id"
                                                                            [(ngModel)]="id">


                                                                        <button pButton pRipple icon="pi pi-save"
                                                                            label="Aggiungi Data"
                                                                            class="p-button pull-right p-button-success p-mt-4"
                                                                            (click)="onSubmitDates()"
                                                                            [disabled]="!datesForm.valid"></button>

                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </form>
                                                </div>
                                            </div>
                                        </div>


                                    </p-card>
                                </div>
                            </div>
                        </p-tabPanel>

                        <p-tabPanel header="Dati aggiuntivi">



                            <p-card>


                                <div class="p-grid">
                                    <div class="p-col-12 p-md-7">
                                        <div class="left-section-card">
                                            <div class="p-fluid">




                                                <table formArrayName="skills" style="width: 100%">

                                                    <tr *ngFor="let quantity of skills.controls; let i=index"
                                                        [formGroupName]="i">
                                                        <td width="100%">

                                                            <p-dropdown [options]="featuresTechnicalData"
                                                                [virtualScroll]="true" filter="true" [showClear]="true"
                                                                placeholder="Scegli" [style]="{ width: '100%' }"
                                                                formControlName="label" optionLabel="name"
                                                                optionValue="id">
                                                                <ng-template let-option pTemplate="item">

                                                                    {{option.name}}

                                                                </ng-template>

                                                            </p-dropdown>

                                                            <input type="text" placeholder="Valore" class="p-mt-2"
                                                                [style]="{ width: '100%' }" pInputText
                                                                formControlName="value">

                                                            <button pButton (click)="removeQuantity(i)"
                                                                icon="pi pi-trash"
                                                                class="p-button p-button-danger pull-left p-mt-2"></button>

                                                        </td>
                                                    </tr>
                                                </table>

                                            </div>
                                        </div>
                                    </div>

                                    <div class="p-col-12 p-md-5">
                                        <div class="p-field p-grid">

                                            <div class="p-col-12">
                                                <div class="well">
                                                    <div class="p-field p-grid">

                                                        <div class="p-col-12">

                                                            <button pButton pRipple icon="pi pi-plus"
                                                                label="Dati aggiuntivi"
                                                                class="p-button pull-left p-button-success p-mr-2"
                                                                (click)="addQuantity()"></button>

                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </p-card>
                        </p-tabPanel>

                        <p-tabPanel header="Seo">

                            <div class="p-grid">

                                <div class="p-col-12 p-md-12">
                                    <p-card>
                                        <div class="p-grid">
                                            <div class="p-col-12 p-md-7">
                                                <div class="left-section-card">
                                                    <div class="p-fluid">


                                                        <div class="p-field p-grid">
                                                            <label class="p-col-12 p-mb-2">SEO title<span
                                                                    class="required">*</span></label>
                                                            <div class="p-col-12 p-md-12">

                                                                <input type="text" [style]="{ width: '100%' }"
                                                                    pInputText formControlName="page_seotitle">

                                                            </div>
                                                        </div>
                                                    </div>

                                               

                                                    <div class="p-fluid">


                                                        <div class="p-field p-grid">
                                                            <label class="p-col-12 p-mb-2">SEO description<span
                                                                    class="required">*</span></label>
                                                            <div class="p-col-12 p-md-12">

                                                                <input type="text" [style]="{ width: '100%' }"
                                                                    pInputText formControlName="page_seodescription">

                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div class="p-fluid">


                                                        <div class="p-field p-grid">
                                                            <label class="p-col-12 p-mb-2">SEO keywords<span
                                                                    class="required">*</span></label>
                                                            <div class="p-col-12 p-md-12">

                                                                <input type="text" [style]="{ width: '100%' }"
                                                                    pInputText formControlName="page_seokeywords">

                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </p-card>
                                </div>
                            </div>
                        </p-tabPanel>
                        <p-tabPanel header="Slideshow" *ngIf="element">


                            <app-upload [element]="element"></app-upload>



                        </p-tabPanel>


                        <p-tabPanel header="Documenti" *ngIf="element">


                            <app-upload-files [element]="element"></app-upload-files>



                        </p-tabPanel>

                    </p-tabView>



                </div>



            </div>

        </div>
    </div>
</form>





<p-confirmDialog [style]="{width: '50vw'}" [baseZIndex]="10000" rejectButtonStyleClass="p-button-text" #cd>

    <p-footer>
        <button type="button" pButton icon="fa fa-close" class="p-button p-button-danger p-mr-2" label="No"
            (click)="cd.reject()"></button>
        <button type="button" pButton icon="fa fa-check" class="p-button p-button-success p-mr-2" label="Si"
            (click)="cd.accept()"></button>
    </p-footer>

</p-confirmDialog>

<p-toast key="myKey1" position="top-right"></p-toast>