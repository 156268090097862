import { Component, OnInit } from "@angular/core";
import { NavigationEnd, Router } from "@angular/router";
import { NgxSpinnerService } from "ngx-spinner";
import { TranslateService } from "@ngx-translate/core";

@Component({
  selector: "app-root",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.css"],
})
export class AppComponent implements OnInit {
  flag = true;

  constructor(
    public route: Router,
    private spinner: NgxSpinnerService,
    private router: Router
  ) {}

  ngOnInit() {}
  onActivate(event) {
    window.scroll(0, 0);
    document.body.scrollTop = 0;
    document.querySelector("body").scrollTo(0, 0);
  }
}
