<app-topnav></app-topnav>

<form [formGroup]="blogForm" (ngSubmit)="onSubmit()">
    <div class="top-main-wrapper">
        <div class="dash-header">
            <div class="dash-title">

                <div class="p-field p-grid">
                    <div class="p-col-6 p-md-6">
                        <h3>{{pageTitle}}</h3>
                    </div>
                    <div class="p-col-6 p-md-6">

                        <button pButton pRipple icon="pi pi-save" label="salva"
                            class="p-button p-button-success p-mr-2 pull-right" [disabled]="!blogForm.valid"></button>

                        <button type="button" pButton pRipple icon="pi pi-chevron-left"
                            label=" indietro" class="p-button p-button-back pull-right  p-mr-2"
                            [routerLink]="['/admin/pages/']"></button>
                    </div>
                </div>

            </div>
        </div>
    </div>

    <div class="main-inner">

        <div class="p-fluid">
            <div class="p-field p-grid">
                <div class="p-col-12 p-md-12">
                    <p-tabView class="responsive-tab-view">



                        <p-tabPanel header="Informazioni">

                            <div class="p-grid">

                                <div class="p-col-12 p-md-12">
                                    <p-card>
                                        <div class="p-grid">
                                            <div class="p-col-12 p-md-7">
                                                <div class="left-section-card">
                                                    <div class="p-fluid">


                                                        <div class="p-field p-grid">
                                                            <label class="p-col-12 p-mb-2">Nome <span
                                                                    class="required">*</span></label>
                                                            <div class="p-col-12 p-md-12">
                                                                <input type="text" pInputText placeholder="Nome"
                                                                    formControlName="page_title" />
                                                            </div>

                                                        </div>
                                                    </div>
                                                    <div class="p-fluid">

                                                        <div class="p-field p-grid">
                                                            <label class="p-col-12 p-mb-2">Page Url <span
                                                                    class="required">*</span></label>
                                                            <div class="p-col-12 p-md-12">
                                                                <input type="text" pInputText appSlugTransform placeholder="Nome"
                                                                    formControlName="page_url" />
                                                            </div>

                                                        </div>
                                                    </div>

                                                    <div class="p-fluid">
                                                        <div class="p-field p-grid">
                                                            <div class="p-col-12 p-mb-2">
                                                                <label class="">Descrizione breve <span
                                                                        class="required">*</span></label>
                                                            </div>
                                                            <div class="p-col-12 p-md-12">

                                                                <p-editor formControlName="description_1"
                                                                    [style]="{ height: '220px' }">
                                                                    <ng-template pTemplate="header">
                                                                        <span class="ql-formats">
                                                                            <button type="button" class="ql-bold"
                                                                                aria-label="Bold"></button>
                                                                            <button type="button" class="ql-italic"
                                                                                aria-label="Italic"></button>
                                                                            <button type="button" class="ql-underline"
                                                                                aria-label="Underline"></button>
                                                                                <button type="button" 
                                                                                class="ql-clean" 
                                                                                aria-label="clean"> 
                                                                            </button> 
                                                                       
                                                                            <button type="button" 
                                                                                class="ql-link" 
                                                                                aria-label="Link"> 
                                                                            </button>
                                                                        </span>
                                                                    </ng-template>
                                                                </p-editor>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div class="p-fluid">
                                                        <div class="p-field p-grid">
                                                            <div class="p-col-12 p-mb-2">
                                                                <label class="">Descrizione breve <span
                                                                        class="required">*</span></label>
                                                            </div>
                                                            <div class="p-col-12 p-md-12">

                                                                <p-editor formControlName="description_2"
                                                                    [style]="{ height: '220px' }">
                                                                    <ng-template pTemplate="header">
                                                                        <span class="ql-formats">
                                                                            <button type="button" class="ql-bold"
                                                                                aria-label="Bold"></button>
                                                                            <button type="button" class="ql-italic"
                                                                                aria-label="Italic"></button>
                                                                            <button type="button" class="ql-underline"
                                                                                aria-label="Underline"></button>
                                                                                <button type="button" 
                                                                                class="ql-clean" 
                                                                                aria-label="clean"> 
                                                                            </button> 
                                                                       
                                                                            <button type="button" 
                                                                                class="ql-link" 
                                                                                aria-label="Link"> 
                                                                            </button>
                                                                        </span>
                                                                    </ng-template>
                                                                </p-editor>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="p-col-12 p-md-5">
                                                <div class="well">

                                <div class="blog-form">
                                    <div class="p-fluid">
                                        <div class="p-grid">
                                            <div class="p-col-12 p-md-4">
                                                <div class="p-grid">

                                                    <label class="p-col-12 p-mb-2">Attivo <span
                                                            class="required">*</span></label>
                                                    <div class="p-col-12 p-md-12">
                                                        <p-selectButton [options]="stateOptions"
                                                            formControlName="is_active"
                                                            optionLabel="label"
                                                            optionValue="value"></p-selectButton>
                                                    </div>
                                                </div>

                                            </div>
                                            <div class="p-col-12 p-md-8">
                                                <div class="p-grid">
                                                    <label class="p-col-12 p-mb-2">In evidenzia <span
                                                            class="required">*</span></label>
                                                    <div class="p-col-12 p-md-12">
                                                        <p-dropdown [options]="statePublished"
                                                            formControlName="status"
                                                            [(ngModel)]="selectedStatePublished"
                                                            optionLabel="label" optionValue="value">
                                                        </p-dropdown>
                                                    </div>
                                                </div>


                                            </div>
                                          
                                        </div>
                                    </div>


                                    <div class="p-fluid p-mt-4">
                                        <div class="p-grid">
                                            <div class="p-col-12 p-md-12">
                                                <hr>
                                                <div class="p-grid p-mt-3">

                                                <label class="p-col-12 p-mb-2">Pagina madre </label>
                                                </div>
                                            </div>
                                           
                                            <div class="p-col-12 p-md-12">
                                                <div class="p-grid">


                                                    <div class="p-col-12 p-md-12">
                                                        <p-dropdown [options]="pagess"
                                                            formControlName="ref_id"
                                                            [(ngModel)]="selectedPagesPublished"
                                                            optionLabel="page_title" optionValue="id">
                                                            
                                                            <ng-template let-page pTemplate="item">
                                                                {{page.page_title}} 
                                                            </ng-template>
                                                        </p-dropdown>
                                                    </div>
                                                </div>


                                            
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                                </div>
                                            </div>
                                        </div>
                                    </p-card>
                                </div>
                            </div>

                        </p-tabPanel>
                        <p-tabPanel header="Dati aggiuntivi">



                            <p-card>


                                <div class="p-grid">
                                    <div class="p-col-12 p-md-7">
                                        <div class="left-section-card">
                                            <div class="p-fluid">




                                                <table formArrayName="skills" style="width: 100%">

                                                    <tr *ngFor="let quantity of skills.controls; let i=index"
                                                        [formGroupName]="i">
                                                        <td width="100%">

                                                            <p-dropdown [options]="featuresTechnicalData"
                                                                [virtualScroll]="true" filter="true" [showClear]="true"
                                                                placeholder="Scegli" [style]="{ width: '100%' }"
                                                                formControlName="label" optionLabel="name"
                                                                optionValue="id">
                                                                <ng-template let-option pTemplate="item">

                                                                    {{option.name}}

                                                                </ng-template>

                                                            </p-dropdown>

                                                            <input type="text" placeholder="Valore" class="p-mt-2"
                                                                [style]="{ width: '100%' }" pInputText
                                                                formControlName="value">

                                                            <button pButton (click)="removeQuantity(i)"
                                                                icon="pi pi-trash"
                                                                class="p-button p-button-danger pull-left p-mt-2"></button>

                                                        </td>
                                                    </tr>
                                                </table>

                                            </div>
                                        </div>
                                    </div>

                                    <div class="p-col-12 p-md-5">
                                        <div class="p-field p-grid">

                                            <div class="p-col-12">
                                                <div class="well">
                                                    <div class="p-field p-grid">

                                                        <div class="p-col-12">

                                                            <button pButton pRipple icon="pi pi-plus"
                                                                label="Dati aggiuntivi"
                                                                class="p-button pull-left p-button-success p-mr-2"
                                                                (click)="addQuantity()"></button>

                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </p-card>
                        </p-tabPanel>


                        <p-tabPanel header="Seo">

                            <div class="p-grid">

                                <div class="p-col-12 p-md-12">
                                    <p-card>
                                        <div class="p-grid">
                                            <div class="p-col-12 p-md-7">
                                                <div class="left-section-card">
                                                    <div class="p-fluid">


                                                        <div class="p-field p-grid">
                                                            <label class="p-col-12 p-mb-2">SEO title<span
                                                                    class="required">*</span></label>
                                                            <div class="p-col-12 p-md-12">

                                                                <input type="text" [style]="{ width: '100%' }"
                                                                    pInputText formControlName="page_seotitle">

                                                            </div>
                                                        </div>
                                                    </div>


                                                    <div class="p-fluid">


                                                        <div class="p-field p-grid">
                                                            <label class="p-col-12 p-mb-2">SEO description<span
                                                                    class="required">*</span></label>
                                                            <div class="p-col-12 p-md-12">

                                                                <input type="text" [style]="{ width: '100%' }"
                                                                    pInputText formControlName="page_seodescription">

                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div class="p-fluid">


                                                        <div class="p-field p-grid">
                                                            <label class="p-col-12 p-mb-2">SEO Keywords<span
                                                                    class="required">*</span></label>
                                                            <div class="p-col-12 p-md-12">

                                                                <input type="text" [style]="{ width: '100%' }"
                                                                    pInputText formControlName="page_seokeywords">

                                                            </div>
                                                        </div>
                                                    </div>

                                                </div>
                                            </div>
                                        </div>
                                    </p-card>
                                </div>
                            </div>
                        </p-tabPanel>


                        <p-tabPanel header="Slideshow" *ngIf="element">


                            <app-upload [element]="element"></app-upload>



                        </p-tabPanel>


                        <p-tabPanel header="Documenti" *ngIf="element">


                            <app-upload-files [element]="element"></app-upload-files>



                        </p-tabPanel>


                    </p-tabView>

                    <div class="form-group form-row">
                        <label class="col-md-3"></label>
                        <div class="col-md-9">
                            <input type="hidden" formControlName="id">
                        </div>
                    </div>




                </div>


            </div>
        </div>
    </div>
</form>
<p-confirmDialog [style]="{width: '50vw'}" [baseZIndex]="10000" rejectButtonStyleClass="p-button-text" #cd>

    <p-footer>
        <button type="button" pButton icon="fa fa-close" class="p-button p-button-danger p-mr-2" label="No"
            (click)="cd.reject()"></button>
        <button type="button" pButton icon="fa fa-check" class="p-button p-button-success p-mr-2" label="Si"
            (click)="cd.accept()"></button>
    </p-footer>

</p-confirmDialog>

<p-toast key="myKey1"></p-toast>