import { Component, ElementRef, OnInit } from "@angular/core";
import { AppointmentsService } from "../../../services/appointments.service";
import {
  UntypedFormBuilder,
  Validators,
  UntypedFormGroup,
  UntypedFormArray,
  FormGroup,
} from "@angular/forms";
import { Router, ActivatedRoute } from "@angular/router";
import { ViewChild } from "@angular/core";
import { Blog } from "../../../models/blog";
import { Category } from "../../../models/category";
import { UntypedFormControl } from "@angular/forms";
import {
  ConfirmationService,
  Message,
  MessageService,
  SelectItem,
} from "primeng/api";
import * as moment from "moment";
import {
  TYPE_LIST,
  STATUS_PRODUCTS,
  STATE_LIST,
  STATE_PUBLISHED,
  FEATURED_OPTIONS,
} from "../../constants/constants";
import { Location } from "@angular/common";
import { Works } from "src/app/models/works";
import { Locations } from "src/app/models/locations";
import { map, tap } from "rxjs/operators";
import { Technical_data } from "src/app/models/technical_data";
import { Observable } from "rxjs";
import { SlideshowService } from "src/app/services/slideshow.service";
import { Slideshow } from "src/app/models/slideshow";
import { Tours } from "src/app/models/tours";
import { NgxSpinnerService } from "ngx-spinner";
import { FeaturesTechnicalDataService } from "src/app/services/features_technical_data";
import { SeoService } from "src/app/services/seoservice";
import { ToursService } from "src/app/services/tours.service";
import { DatesService } from "src/app/services/dates.service";

export interface fPairs {
  label: number;
  value: number;
}

@Component({
  selector: "app-tours-form",
  templateUrl: "./tours-form.component.html",
})
export class ToursFormComponent implements OnInit {
  @ViewChild("myInput", { static: false }) myInputVariable: ElementRef;
  pageTitle: string;
  error: string;
  messages: Message[];
  uploadError: string;
  imagePath: any;
  blogs: Blog;
  blog: Blog;
  id: number;
  category: Category;
  works: any = [];
  work: Works;

  checked: boolean = true;
  selectedValue: string;

  blogForm: FormGroup;
  datesForm: FormGroup;

  typeList: any[];

  statePublished: any[];
  stateOptions: any[];
  technical_datas: any = [];
  technical_data: Technical_data;
  selectedStatePublished: SelectItem[] = [];
  count = 0;
  tags: any = [];
  selectedFiles?: FileList;
  progressInfos: any[] = [];
  message: string[] = [];
  previews: string[] = [];
  imageInfos?: Observable<any>;
  dates: any = [];
  description: any;
  selectedFeartures: SelectItem[] = [];
  selectedFeatures: SelectItem[] = [];
  locations: any = [];
  location: Locations;
  calendarOptions: any;
  cities: Blog[];
  selectedCity: Blog;
  selectedClients: SelectItem[];
  panelClosed = false;
  orderBy: string;
  orderByType: string;
  tour_id: string;
  selectedDate: Date;
  date: Date;
  works_id: any;
  basePath: string;
  category_id: any;
  public dataValues: object;
  pages: any;
  currentUser: any;
  fPairs: any;
  addForm: UntypedFormGroup;
  rows: UntypedFormArray;
  itemForm: UntypedFormGroup;
  skillsForm: UntypedFormGroup;
  skillsValues: any = [];
  datesValues: any = [];
  trackByFn(index, item) {
    return item.id;
  }
  element: Tours;
  slideshows: any = [];
  slideshow: Slideshow;
  documents: any = [];
  selectedItemDisplay: string;
  featuresTechnicalData: Category[] = [];
  data: any;
  displayEvent: any;
  visibleDates: boolean;
  cols: any[];
  colsData: any[];
  colsVariations: any[];
  page = 1;
  pageSize = 10;
  pageSizes = [5, 10, 15];
  tourTitle: string;
  featuredOptions: any[];

  constructor(
    private fb: UntypedFormBuilder,
    private messageService: MessageService,
    private _location: Location,
    private toursService: ToursService,
    private datesService: DatesService,
    private spinner: NgxSpinnerService,
    private seoService: SeoService,
    private confirmationService: ConfirmationService,
    private router: Router,
    private featuresTechnicalDataService: FeaturesTechnicalDataService,
    private slideshowService: SlideshowService,
    private route: ActivatedRoute
  ) {
    this.basePath = window.location.pathname;
    if (this.route.snapshot.queryParamMap.has("page")) {
      this.page = +this.route.snapshot.queryParamMap.getAll("page");
    }
    if (this.route.snapshot.queryParamMap.has("size")) {
      this.pageSize = +this.route.snapshot.queryParamMap.getAll("size");
    }

    if (this.date) {
      this.selectedDate = new Date(this.date);
    }
    this.typeList = TYPE_LIST;
    this.statePublished = STATE_PUBLISHED;
    this.featuredOptions = FEATURED_OPTIONS;
    this.stateOptions = STATE_LIST;
    this.data = [];
  }

  ngOnInit() {
    this.spinner.show();

    const id = this.route.snapshot.paramMap.get("id");

    this.load();
    this.cols = [
      { field: "date", header: "Data" },
      { field: "time", header: "Orario" },
    ];

    if (id) {
      this.pageTitle = "Modifica Prodotto";

      this.toursService.getId(+id).subscribe((res) => {
        this.element = res;
        this.imageInfos = this.slideshowService.getId(+id);

        this.blogForm.patchValue({
          page_title: res.page_title,
          description_1: res.description_1,
          description_2: res.description_2,
          page_url: res.page_url,
          is_active: res.is_active,
          page_seotitle: res.page_seotitle,
          page_seokeywords: res.page_seokeywords,
          page_seodescription: res.page_seodescription,
          status: res.status,
          homepage: res.homepage,
          slider: res.slider,
          id: res.id,
          skills: this.skillsValues,
        });
        this.imagePath = res.image;
        this.id = res.id;
        this.tourTitle = res.page_title;
      });
    } else {
      this.pageTitle = "Aggiungi Prodotto";
    }

    this.blogForm = this.fb.group({
      id: [""],
      page_title: ["", Validators.required],
      description_1: [""],
      description_2: [""],
      page_seotitle: [""],
      page_seokeywords: [""],
      page_seodescription: [""],
      is_active: [""],
      slider: [""],
      homepage: [""],
      status: [""],
      page_url: [""],
      skills: this.initSkill(),
    });

    this.datesForm = this.fb.group({
      id: [""],
      tour_id: ["", Validators.required],
      date: ["", Validators.required],
      time: ["", Validators.required],
    });



    this.slideshowService.getId(+id).subscribe((pData) => {
      this.slideshows = pData;
    });

    this.featuresTechnicalDataService.getAllList().subscribe((pData) => {
      this.featuresTechnicalData = pData;
    });

    setTimeout(() => {
      this.spinner.hide();
    }, 200);
  }

  public handlePageChange(event): void {
    this.page = event;
    this.load();
  }

  getRequestParams(
    searchTitle: string,
    page: number,
    pageSize: string | number,
    orderBy: string,
    orderByType: string
  ): any {
    // tslint:disable-next-line:prefer-const
    let params: any = {};
    let adder = "?";
    if (page) {
      params[`page`] = page - 1;
      adder + "page=" + (page - 1);
      adder = "&";
    }
    if (searchTitle) {
      params[`tour_id`] = searchTitle;
      adder + "tour_id=" + searchTitle;
      adder = "&";
    }

    if (pageSize) {
      params[`size`] = pageSize;
      adder + "size=" + pageSize;
    }

    if (orderBy) {
      params[`orderBy`] = orderBy;
      adder + "orderBy=" + orderBy;
    }

    if (orderByType) {
      params[`orderByType`] = orderByType;
      adder + "orderByType=" + orderByType;
    }

    //window.history.replaceState({}, '', path);
    return params;
  }

  load(): void {
    const id = this.route.snapshot.paramMap.get("id");

    const params = this.getRequestParams(
      (this.tour_id = id),
      this.page,
      this.pageSize,
      (this.orderBy = "desc"),
      (this.orderByType = "page_id")
    );
    this.datesService.getAllListNew(params).subscribe((pData) => {
      this.dates = pData;
      this.count = this.datesService.size;
    });
  }

  dayClick(event) {
    this.visibleDates = true;
  }

  initSkill() {
    var formArray = this.fb.array([]);
    const id = this.route.snapshot.paramMap.get("id");

    this.toursService.skills(+id).subscribe((res) => {
      this.skillsValues = res;
      this.skillsValues.forEach((e) => {
        formArray.push(
          this.fb.group({
            label: [e.label],
            value: [e.value],
          })
        );
      });
    });

    /*formArray.push(this.fb.group({
      qty: [''],
      price: ['']
    })) */

    return formArray;
  }

  private createSkillFormGroup(skill: any): UntypedFormGroup {
    return new UntypedFormGroup({
      label: new UntypedFormControl(skill.label),
      value: new UntypedFormControl(skill.value),
    });
  }

  public addSkill(skill: any) {
    this.skills.push(this.createSkillFormGroup(skill));
  }

  get skills() {
    return this.blogForm.get("skills") as UntypedFormArray;
  }

  addQuantity() {
    this.skills.push(this.newQuantity());
  }

  removeQuantity(i: number) {
    this.skills.removeAt(i);
  }

  newQuantity(): UntypedFormGroup {
    return this.fb.group({
      label: "",
      value: "",
    });
  }

  onSubmit() {
    const formData = new FormData();
    formData.append("page_title", this.blogForm.get("page_title").value);
    formData.append("page_url", this.blogForm.get("page_url").value);
    formData.append("is_active", this.blogForm.get("is_active").value);
    formData.append("slider", this.blogForm.get("slider").value);
    formData.append("homepage", this.blogForm.get("homepage").value);
    formData.append("description_1", this.blogForm.get("description_1").value);
    formData.append("description_2", this.blogForm.get("description_2").value);
    formData.append("page_seotitle", this.blogForm.get("page_seotitle").value);
    formData.append(
      "page_seodescription",
      this.blogForm.get("page_seodescription").value
    );
    formData.append(
      "page_seokeywords",
      this.blogForm.get("page_seokeywords").value
    );
    formData.append("status", this.blogForm.get("status").value);
    //formData.append('skills', JSON.stringify(this.blogForm.get('skills').value));
    formData.append(
      "skills",
      JSON.stringify(this.blogForm.get("skills").value)
    );

    const id = this.blogForm.get("id").value;

    if (id) {
      this.toursService.update(formData, +id).subscribe(
        (res) => {
          if (res.status == "error") {
            this.messageService.add({
              key: "myKey1",
              severity: "warn",
              summary: "Informazioni",
              detail: "Errore",
            });

            this.uploadError = res.message;
          } else {
            this.messageService.add({
              key: "myKey1",
              severity: "success",
              summary: "Informazioni",
              detail: "Salvato con sucesso",
            });
            //this.router.navigate(['/admin/products']);
          }
        },
        (error) => (this.error = error)
      );
    } else {
      this.toursService.create(formData).subscribe(
        (res) => {
          if (res.status === "error") {
            this.uploadError = res.message;
          } else {
            this.messageService.add({
              key: "myKey1",
              severity: "success",
              summary: "Informazioni",
              detail: "Salvato con sucesso",
            });
            this.router.navigate(["/admin/tours"]);
          }
        },
        (error) => (this.error = error)
      );
    }
  }

  onDelete(id: number, title: string) {
    this.confirmationService.confirm({
      message: "Sei sicuro di volerlo cancellare = " + id,
      header: "Confirmation",
      icon: "pi pi-exclamation-triangle",
      accept: () => {
        this.datesService.delete(+id).subscribe(
          (res) => {
            console.log(res);
            this.ngOnInit();
            this.messageService.add({
              key: "myKey1",
              severity: "warn",
              summary: "Attenzione",
              detail: "Cancellazione avvenuto con successo",
            });
          },
          (error) => (this.error = error)
        );
      },
    });
  }

  onSubmitDates() {
    const formData = new FormData();

    formData.append("tour_id", this.datesForm.get("tour_id").value);
    formData.append("date", this.datesForm.get("date").value);
    formData.append("time", this.datesForm.get("time").value);

    this.datesService.create(formData).subscribe(
      (res) => {
        if (res.status === "error") {
          this.uploadError = res.message;
        } else {
          this.messageService.add({
            key: "myKey1",
            severity: "success",
            summary: "Informazioni",
            detail: "Salvato con sucesso",
          });
          this.datesForm.reset({
            date: "",
            tour_id: this.id,
          });
          this.load();
        }
      },
      (error) => (this.error = error)
    );
  }
}
